import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

//import DefaultOGImage from 'images/social-graph/open-graph-logo.png'


function SEO({
  lang,
  title,
  description,
  robots,
  location,
  ogImg,
  ogImgAlt,
  isBlogPost,
  meta,
}) {
/*   const data = useStaticQuery(graphql`
      query siteSettingsQuery {
        settingsJson {
          siteName
          siteDescription
          siteUrl
          siteVerificationTokens {
            googleToken
            bingToken
          }
        }
      }
  `) */

  const data = {
    settingsJson: {
      siteName: `The Claxton Bank`,
      siteDescription: `The Claxton Bank is the premier community bank in Evans, Chatham, Bryan, and surrounding counties.`,
      siteUrl: "https://tcbga.bank/",
      siteVerificationTokens: {
        googleToken: `123456`,
        bingToken: `123456`,
      }
    }
  }


  // The html lang code only allows for two chars so remove the '-us'
  const htmlLang = lang.substr(0, 2)

  // If a page description isn't passed use the default site description
  const metaDescription = description || data.settingsJson.siteDescription

  // Create new copyright date everytime the site is built and append the site name
  const copyrightText = `Copyright ${new Date().getFullYear()} - ${data.settingsJson.siteName}`

  // Set the social image
  const socialImg = ogImg || `` //DefaultOGImage
  const socialImgAlt = ogImgAlt || `The Claxton Bank Logo`

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: htmlLang,
        }}
        title={title}
      meta={[
        { name: `description`, content: metaDescription },
        { name: `language`, content: lang },
        { name: `copyright`, content: copyrightText },
        { name: `robots`, content: robots },

        // Facebook OpenGraph Tags
        { property: `og:title`, content: title },
        { property: `og:description`, content: metaDescription },
        { property: `og:site-name`, content: data.settingsJson.siteName },
        { property: `og:url`, content: `${data.settingsJson.siteUrl + location}`},
        { property: `og:image`, content: `${data.settingsJson.siteUrl + socialImg}`},
        { property: `og:image:alt`, content: socialImgAlt },
        { property: "og:locale", content: lang },
        { property: "og:type", content: isBlogPost ? `article` : `website` },

        // Site Ownership Verification
        { name: `google-site-verification`, content: data.settingsJson.siteVerificationTokens.googleToken },
        { name: `msvalidate.01`, conrent: data.settingsJson.siteVerificationTokens.bingToken },

        

      ].concat(meta)}
    >
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `en-us`,
  description: ``,
  robots: `index, follow`,
  ogImg: ``,
  ogImgAlt: ``,
  isBlogPost: false,
  meta: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  location: PropTypes.string.isRequired,
  robots: PropTypes.string,
  ogImg: PropTypes.string,
  ogImgAlt: PropTypes.string,
  isBlogPost: PropTypes.bool,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO